import React from 'react'
import About from '../components/about/About'
import Lines from '../components/lines/Lines'
import Contact from '../components/contact/Contact'
import Map from '../components/mapa/Map'

const AboutUs = () => {
  return (
    <>
        <About />
        <Lines />
        <Contact />
        <Map/>
    </>
  )
}

export default AboutUs