import React from 'react';
import { Route, Routes } from "react-router-dom";
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import Shop from './pages/Shop';
import AboutUs from './pages/AboutUs';


const App = () => {
  return (
    <>       
        <Nav />

        <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/aboutus' element={<AboutUs />}/>
        </Routes>
        
        <Footer />
    </>
  )
}

export default App