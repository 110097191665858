import React from 'react'
import Catalogofull from '../components/catalogofull/Catalogofull'
import Contact from '../components/contact/Contact'

const Shop = () => {
  return (
    <>
    <Catalogofull/>
    <Contact/>
    </>
  )
}

export default Shop