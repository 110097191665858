import React from 'react'
import './header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <div id='jumbotron'>

      

      
      <div className="header__container mask">

        <div className="header__content">
          <h1>SAVINO</h1>
          <h3>EQUIPOS MEDICOS</h3>

          <CTA/>
          <HeaderSocials/>
        </div>
        
       
      </div>
    </div>
  )
}

export default Header