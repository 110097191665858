import React from 'react'
import './footer.css'
import {BsEnvelopeFill} from 'react-icons/bs'
import {FiInstagram} from 'react-icons/fi'
import {FaLinkedinIn} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <a href="https://www.savino-srl.com.ar/" className='footer__logo'>SAVINO</a>

      <ul className='permalinks'>
      
        
        <li>
          <Link to='/'>
            <a href="/">INICIO</a>
          </Link>
        </li>
        <li>
          <Link to='/aboutus'>
            <a href='#about'>NOSOTROS</a>
          </Link>
        </li>
        <li>
          <Link to='/shop'>
            <a href='#catalogo'>CATALOGO</a>
          </Link>
        </li>
        <li><a href="#contact">CONTACTO</a></li>
      </ul>

      <div className="footer__socials">
        <a href="#contact" target='_blank' rel="noreferrer"><FaFacebookSquare/></a>
        <a href="mailto:savinoequipo@hotmail.com" target='_blank' rel="noreferrer"><BsEnvelopeFill/></a>
        <a href="https://www.instagram.com/savinodontologia/" target='_blank' rel="noreferrer"><FiInstagram/></a>
        <a href="https://www.linkedin.com/company/savino-srl/" target='_blank' rel="noreferrer"><FaLinkedinIn/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Powered by <a href="https://brisider-33980.web.app/" target='_blank' rel="noreferrer">BRISIDER</a>.</small>
      </div>
    </footer>
  )
}

export default Footer