import React from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import './nav.css';
import Logo from '../../assets/savinoLogo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const Nav = () => {  
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive__nav");
  }

  return (

    <header>
      
      <Link to='/'>
        <div className="brand"><img src={Logo} alt="LOGO" /></div>
      </Link>

      <nav ref={navRef}>

        <Link to='/'>
          <a href="/" onClick={showNavbar}>INICIO</a>
        </Link>
        <Link to='/shop'>
          <a href='#catalogo' onClick={showNavbar}>CATALOGO</a>
        </Link>
        <Link to='/aboutus'>
          <a href='#about' onClick={showNavbar}>NOSOTROS</a>
        </Link>
        <a href="#contact" onClick={showNavbar}>CONTACTO</a>

        <button className='nav__btn nav__close__btn' onClick={showNavbar}>
          <FaTimes/>
        </button>
        
      </nav>

      <button className='nav__btn' onClick={showNavbar}>
        <FaBars/>
      </button>
    </header>

  )
}

export default Nav