import React from 'react'
import './map.css'

const Map = () => {
  return (
    <section id='map__mapa'>
        <div className="container map__Pintos91__container">

        <article>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d851.0132108302778!2d-58.47291984958722!3d-34.673044778719486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc95a82645abb%3A0x930893039ad783f1!2sSavino!5e0!3m2!1ses-419!2sar!4v1665498917774!5m2!1ses-419!2sar" width="100%"></iframe>
        </article>
            
        </div>
    </section>
  )
}

export default Map