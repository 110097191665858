import React from 'react'
import { Link } from 'react-router-dom';
import './catalogo.css'
import BLINDADAS from '../../assets/sillonAmarillo-PhotoRoom.png'
import PLANAS from '../../assets/sillonRojo-PhotoRoom.png'
import PODOLOGIA from '../../assets/sillonPodologia-PhotoRoom.png'


const data = [
    {
        id:1,
        image: BLINDADAS,
        title: 'Sillones Odontológicos'      
    },
    {
        id:2,
        image: PODOLOGIA,
        title: 'Sillones Podológicos'       
    },
    {
        id:3,
        image: PLANAS,
        title: 'Sillones Odontológicos'        
    }
]

const Catalogo = () => {
    return (
      <section id='catalogo'>
          <h5>EQUIPOS MEDICOS</h5>
          <h2>CATALOGO</h2>

          <div className="container catalogo__container">
            {
                data.map(({id, image, title, detail}) =>{
                    return(
                        <article key={id} className='catalogo__item'>
                            <div className="catalogo__item-image">
                                <img src={image} alt={title} />
                            </div>
                            <h3>{title}</h3>
                            <p>{detail}</p>
                            <div className="catalogo__item-cta">
                                <a href="#contact" className='btn btn-primary' >CONTACTO</a>
                                <a href="https://api.whatsapp.com/send?phone=5491159393931&text=Hola!%20Necesito%20informaci%C3%B3n%20sobre%20un%20producto%20de%20la%20web%20" className='btn btn-succes'  target="_blank" rel="noreferrer">WHATSAPP</a>
                            </div>
                        </article>
                    )
                })
            }
          </div>

          <Link to='/shop'>
            <a href='#catalogo' id='catalogo__btn' className='btn btn-primary'>VER MAS</a>
          </Link>

          

      </section>
    )
  }
  
  export default Catalogo