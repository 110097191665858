import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {BiMap} from 'react-icons/bi'

const Contact = () => {
  

  return (
    <section id='contact'>
      <h5>Vías De</h5>
      <h2>Contacto</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>11-5939-3931</h5>
            <a href="https://api.whatsapp.com/send?phone=5491159393931&text=Hola!%20Necesito%20informaci%C3%B3n%20sobre%20un%20producto%20de%20la%20web%20" target='_blank' rel="noreferrer" className='btn btn-primary'>Contactarse</a>
          </article>
          <article className='contact__option'>
            <BiMap className='contact__option-icon'/>
            <h4>Ubicación</h4>
            <h5>Fonrouge 3870, CABA.</h5>
            <a href="#map__mapa" className='btn btn-primary'>Contactarse</a>
          </article>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>savinoequipo@hotmail.com</h5>
            <a href="mailto:savinoequipo@hotmail.com" target='_blank' rel="noreferrer" className='btn btn-primary'>Contactarse</a>
          </article>
          
        </div>

        <form action="https://formsubmit.co/savinoequipo@hotmail.com" method="POST" >
          <input type="text" name='name' placeholder='Nombre Completo' required />
          <input type="email" name='email' placeholder='correo@mail.com' required />
          <input type="number" name='number' placeholder='Teléfono' />
          <textarea name="message"rows="7" placeholder='Escribir mensaje...' required></textarea>
          <button type='submit' className='btn btn-primary'>Enviar Mensaje</button>
        </form>


      </div>
    </section>
  )
}

export default Contact