import React from 'react'
import './catalogofull.css'

import AMARILLO from '../../assets/sillonAmarillo-PhotoRoom.png'
import VERDE from '../../assets/sillonCeleste-PhotoRoom.png'
import ROJO from '../../assets/sillonRojo-PhotoRoom.png'
import PODOLOGIA from '../../assets/sillonPodologia-PhotoRoom.png'
import GINECOLOGIA from '../../assets/sillonGinecologico-PhotoRoom.png'
import CAMILLA from '../../assets/camillaFija-PhotoRoom.png'


const data = [
    {
        id:1,
        image: AMARILLO,
        title: 'Sillones Odontológicos'      
    },
    {
        id:2,
        image: PODOLOGIA,
        title: 'Sillones Podológicos'       
    },
    {
        id:3,
        image: ROJO,
        title: 'Sillones Odontológicos'        
    },
    {
        id:4,
        image: CAMILLA,
        title: 'Camilla Fija'               
    },
    {
        id:5,
        image: GINECOLOGIA,
        title: 'Sillones Ginecológicos'        
    },
    {
        id:6,
        image: VERDE,
        title: 'Sillones Odontológicos'                
    }
]

const Catalogofull = () => {
  return (
    <section id='catalogo'>
        <h5>EQUIPOS MEDICOS</h5>
        <h2>CATALOGO</h2>

        <div className="container catalogo__container">
        {
            data.map(({id, image, title, detail}) =>{
                return(
                    <article key={id} className='catalogo__item'>
                        <div className="catalogo__item-image">
                            <img src={image} alt={title} />
                        </div>
                        <h3>{title}</h3>
                        <p>{detail}</p>
                        <div className="catalogo__item-cta">
                            <a href="#contact" className='btn btn-primary' >CONTACTO</a>
                            <a href="https://api.whatsapp.com/send?phone=5491159393931&text=Hola!%20Necesito%20informaci%C3%B3n%20sobre%20un%20producto%20de%20la%20web%20" className='btn btn-succes'  target="_blank" rel="noreferrer">WHATSAPP</a>
                        </div>
                    </article>
                )
            })
        }
        </div>
    </section>

  )
}

export default Catalogofull