import React from 'react'
import './about.css'
import US from '../../assets/headerImg.jpg'

const about = () => {
  return (
    <section id='about'>
      <h5>Acerca De</h5>
      <h2>Nosotros</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={US} alt="RESISTEN" />
          </div>
        </div>

        <div className="about__content">

          <h2>SAVINO</h2>
          <h5>EQUIPOS MEDICOS</h5>
          <p>
            Con más de 35 años de trayectoria en la industria dental decidimos expandirnos 
            a los rubros que necesiten toda clase de equipamientos ya sean 
            Odontológicos, Ginecológicos, Podológicos, etc. Nuestros productos tienen garantizada 
            la solidez de siempre.<br/>
            Pueden visitarnos en <a href="#map__mapa" className="text-info text-uppercase"> nuestro local</a>.
          </p>
          

          <a href="#contact" className='btn btn-primary'>Contactanos</a>
        </div>
      </div>
    </section>
  )
}

export default about